// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ChassisRentalLogger-InspectionModal-Paper-Dialog {
  min-height: 80%;
  max-height: 80%;
  height: 80%;
}

body .ChassisRentalLoger-InspectionModal-Grid-Container {
  padding: 30px 5% 30px 5%;
}

body .ChassisRentalLoger-InspectionModal-title-text {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

body .ChassisRentalLoger-InspectionModal-subtitle-text {
  font-size: 1em;
  font-weight: 500;
  margin-top: 10px;
  color: #222152;
}

body .ChassisRentalLogger-InspectionModal-radiogroup-refurbished {
  display: flex;
  justify-content: space-between;
}

body .ChassisRentalLoger-InspectionModal-grid-item-iep-vin {
  display: flex;
  align-items: flex-end;
}

body .ChassisRentalLogger-InspectionModal-MobileStepper {
  position: sticky;
  bottom: 0;
  background-color: #222152;
  z-index: 1000;
}

body .ChassisRentalLogger-InspectionModal-InspectorName {
  text-transform: capitalize !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/InspectionModal/InspectionModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":["body .ChassisRentalLogger-InspectionModal-Paper-Dialog {\r\n  min-height: 80%;\r\n  max-height: 80%;\r\n  height: 80%;\r\n}\r\n\r\nbody .ChassisRentalLoger-InspectionModal-Grid-Container {\r\n  padding: 30px 5% 30px 5%;\r\n}\r\n\r\nbody .ChassisRentalLoger-InspectionModal-title-text {\r\n  font-size: 1.5em;\r\n  font-weight: bold;\r\n  margin-bottom: 10px;\r\n}\r\n\r\nbody .ChassisRentalLoger-InspectionModal-subtitle-text {\r\n  font-size: 1em;\r\n  font-weight: 500;\r\n  margin-top: 10px;\r\n  color: #222152;\r\n}\r\n\r\nbody .ChassisRentalLogger-InspectionModal-radiogroup-refurbished {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\nbody .ChassisRentalLoger-InspectionModal-grid-item-iep-vin {\r\n  display: flex;\r\n  align-items: flex-end;\r\n}\r\n\r\nbody .ChassisRentalLogger-InspectionModal-MobileStepper {\r\n  position: sticky;\r\n  bottom: 0;\r\n  background-color: #222152;\r\n  z-index: 1000;\r\n}\r\n\r\nbody .ChassisRentalLogger-InspectionModal-InspectorName {\r\n  text-transform: capitalize !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
