// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ChassisRentalLogger-InputOkDefectRepair-grid-items {
  display: flex;
  justify-content: center;
}

body .ChassisRentalLogger-InputOkDefectRepair-subtitle-label {
  font-size: 1rem;
  font-weight: 500;
  color: #222152;
  text-align: center;
}
body .ChassisRentalLoger-InspectionModal-subtitle-grid-item {
  display: flex;
}
body .ChassisRentalLoger-InspectionModal-subtitle-icon {
  scale: 0.6;
  color: grey;
  align-self: end;
}

body .ChassisRentalLoger-InspectionModal-subtitle-icon:hover {
  scale: 0.6;
  cursor: pointer;
  color: #222152;
  align-self: end;
}

body .error {
  color: red;
  scale: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/OkDefectRepairInputs2/OkDefectRepairInputs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,UAAU;AACZ","sourcesContent":["body .ChassisRentalLogger-InputOkDefectRepair-grid-items {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n\r\nbody .ChassisRentalLogger-InputOkDefectRepair-subtitle-label {\r\n  font-size: 1rem;\r\n  font-weight: 500;\r\n  color: #222152;\r\n  text-align: center;\r\n}\r\nbody .ChassisRentalLoger-InspectionModal-subtitle-grid-item {\r\n  display: flex;\r\n}\r\nbody .ChassisRentalLoger-InspectionModal-subtitle-icon {\r\n  scale: 0.6;\r\n  color: grey;\r\n  align-self: end;\r\n}\r\n\r\nbody .ChassisRentalLoger-InspectionModal-subtitle-icon:hover {\r\n  scale: 0.6;\r\n  cursor: pointer;\r\n  color: #222152;\r\n  align-self: end;\r\n}\r\n\r\nbody .error {\r\n  color: red;\r\n  scale: 0.8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
