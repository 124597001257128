// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ChassisRentalLogger-ChassisTruckImage {
  max-width: 80%;
}

body .ChassisRentalLogger-ChassisTruckImage-grid-image {
  display: flex;
  justify-content: center;
}
body .ChassisRentalLogger-ChassisTruckImage-grid-textfields {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/ChassisTruckImage/ChassisTruckImage.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,eAAe;EACf,4BAA4B;AAC9B","sourcesContent":["body .ChassisRentalLogger-ChassisTruckImage {\r\n  max-width: 80%;\r\n}\r\n\r\nbody .ChassisRentalLogger-ChassisTruckImage-grid-image {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\nbody .ChassisRentalLogger-ChassisTruckImage-grid-textfields {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  align-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
