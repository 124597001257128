// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#titleTable-pending-inspect {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    background-color: #23224f;
    padding: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.buttonRequest-pending-inspect:hover {
    scale: 1.2;
    color: #6562e7;

}

.button-good-true {
    border: solid 2px;
    color: white;
    background-color: #15b54f;
    scale: 1.2;
    padding: 1%;
    margin-right: 5px;
    border-radius: 7px;
}

.button-good {
    color: #15b54f;
    margin-right: 5px;
    cursor: pointer;
    border: solid 2px;
    border-radius: 7px;
}

.button-bad {
    color: #ff295e;
    margin-left: 5px;
    cursor: pointer;
    border: solid 2px;
    border-radius: 7px;
}

.button-bad-true {
    border: solid 2px;
    color: white;
    margin-left: 5px;
    background-color: #ff295e;
    scale: 1.2;
    padding: 1%;
    border-radius: 7px;
}

.grounded-cell{
    color: #fc4817;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/table.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,UAAU;IACV,cAAc;;AAElB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#titleTable-pending-inspect {\n    display: flex;\n    justify-content: center;\n    font-size: 1.5rem;\n    font-weight: 700;\n    color: white;\n    background-color: #23224f;\n    padding: 5px;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n}\n\n.buttonRequest-pending-inspect:hover {\n    scale: 1.2;\n    color: #6562e7;\n\n}\n\n.button-good-true {\n    border: solid 2px;\n    color: white;\n    background-color: #15b54f;\n    scale: 1.2;\n    padding: 1%;\n    margin-right: 5px;\n    border-radius: 7px;\n}\n\n.button-good {\n    color: #15b54f;\n    margin-right: 5px;\n    cursor: pointer;\n    border: solid 2px;\n    border-radius: 7px;\n}\n\n.button-bad {\n    color: #ff295e;\n    margin-left: 5px;\n    cursor: pointer;\n    border: solid 2px;\n    border-radius: 7px;\n}\n\n.button-bad-true {\n    border: solid 2px;\n    color: white;\n    margin-left: 5px;\n    background-color: #ff295e;\n    scale: 1.2;\n    padding: 1%;\n    border-radius: 7px;\n}\n\n.grounded-cell{\n    color: #fc4817;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
