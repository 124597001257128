// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .PendingInspect-Fieldrow-Button{
    background-color: #f5f5f5;
    color: #23224f;
    border: 1px solid #23224f;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
}

body .button-selected{
    background-color: #23224f;
    color: #f5f5f5;
}

body .PendingInspect-Fieldrow-Add-ChassisToInspect-Button{
    background-color: #f5f5f5;
    color: #23224f;
    border: 1px solid #23224f;
    border-radius: 100%;
    padding: 5px 5px;
    cursor: pointer;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/fieldRow/fieldRow.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["body .PendingInspect-Fieldrow-Button{\r\n    background-color: #f5f5f5;\r\n    color: #23224f;\r\n    border: 1px solid #23224f;\r\n    border-radius: 5px;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n    margin-right: 10px;\r\n}\r\n\r\nbody .button-selected{\r\n    background-color: #23224f;\r\n    color: #f5f5f5;\r\n}\r\n\r\nbody .PendingInspect-Fieldrow-Add-ChassisToInspect-Button{\r\n    background-color: #f5f5f5;\r\n    color: #23224f;\r\n    border: 1px solid #23224f;\r\n    border-radius: 100%;\r\n    padding: 5px 5px;\r\n    cursor: pointer;\r\n    margin-right: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
